import React, { useState, useEffect, useRef, useContext } from 'react'

import { useHistory, useParams } from 'react-router-dom'
import { parseISO, format, isSameDay } from 'date-fns'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import { grey } from '@material-ui/core/colors'
import { i18n } from '../../translate/i18n'

import api from '../../services/api'
import ButtonWithSpinner from '../ButtonWithSpinner'
import MarkdownWrapper from '../MarkdownWrapper'
import {
    IconButton,
    Tooltip,
    ListItem,
    ListItemText,
    ListItemAvatar,
    ListItemSecondaryAction,
    Typography,
    Avatar,
    Box,
} from '@material-ui/core'
import { AuthContext } from '../../context/Auth/AuthContext'
import { TicketsContext } from '../../context/Tickets/TicketsContext'
import toastError from '../../errors/toastError'
import { v4 as uuidv4 } from 'uuid'

import ContactTag from '../ContactTag'

import AndroidIcon from '@material-ui/icons/Android'
import ScheduleIcon from '@material-ui/icons/Schedule'
import PersonIcon from '@material-ui/icons/Person'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import ReplayIcon from '@material-ui/icons/Replay'
import DoneAllIcon from '@material-ui/icons/DoneAll'
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite'
import CloseTicketModal from 'components/CloseTicketModal'

const useStyles = makeStyles(theme => ({
    avatar: {
        width: '55px',
        height: '55px',
        borderRadius: '10%',
        marginRight: 5,
    },

    contactLastMessage: {
        display: 'flex',
        flexDirection: 'column',

        '& > span': {
            maxWidth: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    },

    tags: {
        display: 'flex',
    },

    ticketInfo: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        alignItems: 'end',
        maxWidth: '33%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',

        '& > p': {
            display: 'flex',
            alignItems: 'center',
            lineHeight: 1.1,
        },
    },

    ticketUser: {
        fontWeight: 700,
    },

    actionButtons: {
        right: 10,
        display: 'flex',
        flexDirection: 'column',

        '& > button': {
            maxWidth: '100%',
        }
    },
}))

const TicketListItemCustom = ({ ticket }) => {
    const classes = useStyles()
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [ticketUser, setTicketUser] = useState(null)
    const [tag, setTag] = useState([])
    const [openFinish, setOpenFinish] = useState(false)

    const { ticketId } = useParams()
    const isMounted = useRef(true)
    const { setCurrentTicket } = useContext(TicketsContext)
    const { user } = useContext(AuthContext)

    useEffect(() => {
        if (ticket.userId && ticket.user) setTicketUser(ticket.user?.name)

        setTag(ticket?.tags)

        return () => {
            isMounted.current = false
        }
    }, [ticket])

    const handleReopenTicket = async id => {
        setLoading(true)
        try {
            await api.put(`/tickets/${id}`, {
                status: 'open',
                userId: user?.id,
                queueId: ticket?.queue?.id,
                useIntegration: false,
            })
        } catch (err) {
            setLoading(false)
            toastError(err)
        }
        if (isMounted.current) {
            setLoading(false)
        }
        history.push(`/tickets/${ticket.uuid}`)
    }

    const handleAcepptTicket = async id => {
        setLoading(true)
        try {
            await api.put(`/tickets/${id}`, {
                status: 'open',
                userId: user?.id,
                useIntegration: false,
                flowId: null,
                step: null,
            })

            let settingIndex

            try {
                const { data } = await api.get('/settings/')

                settingIndex = data.filter(
                    s => s.key === 'sendGreetingAccepted',
                )
            } catch (err) {
                toastError(err)
            }

            if (settingIndex[0].value === 'enabled' && !ticket.isGroup) {
                handleSendMessage(ticket.id)
            }
        } catch (err) {
            setLoading(false)

            toastError(err)
        }
        if (isMounted.current) {
            setLoading(false)
        }

        history.push(`/tickets/${ticket.uuid}`)
    }

    const handleSendMessage = async id => {
        const msg = `{{ms}} *{{name}}*, meu nome é *${user?.name}* e agora vou prosseguir com seu atendimento!`
        const message = {
            read: 1,
            fromMe: true,
            mediaPath: '',
            body: `*Mensagem Automática:*\n${msg.trim()}`,
        }
        try {
            await api.post(`/messages/${id}`, message)
        } catch (err) {
            toastError(err)
        }
    }

    const handleSelectTicket = ticket => {
        const code = uuidv4()
        const { id, uuid } = ticket
        setCurrentTicket({ id, uuid, code })
    }

    const renderTicketInfo = () => {
        return (
            <>
                {ticket.chatbot && (
                    <Tooltip title="Chatbot">
                        <AndroidIcon
                            fontSize="small"
                            style={{ color: grey[700], marginRight: 5 }}
                        />
                    </Tooltip>
                )}
            </>
        )
    }

    const renderLastMessage = () => {
        if (ticket.lastMessage?.includes('data:image/png;base64'))
            return <MarkdownWrapper>Localização</MarkdownWrapper>

        if (ticket.lastMessage?.includes('BEGIN:VCARD'))
            return <MarkdownWrapper>Contato</MarkdownWrapper>

        return <MarkdownWrapper>{ticket.lastMessage}</MarkdownWrapper>
    }

    return (
        <ListItem
            key={ticket.id}
            dense
            button
            divider
            onClick={() => {
                handleSelectTicket(ticket)
            }}
            selected={ticketId && +ticketId === ticket.id}
            className={clsx({
                [classes.pendingTicket]: ticket.status === 'pending',
            })}>
            <CloseTicketModal ticket={ticket} open={openFinish} onClose={() => setOpenFinish(false)} />
            <ListItemAvatar>
                <Avatar
                    classes={{ root: classes.avatar }}
                    src={ticket?.contact?.profilePicUrl}
                />
            </ListItemAvatar>
            <ListItemText
                disableTypography
                primary={
                    <span className={classes.contactNameWrapper}>
                        <Typography
                            noWrap
                            component="span"
                            variant="body2"
                            color="textPrimary">
                            {ticket.contact.name}
                        </Typography>
                        <ListItemSecondaryAction>
                            <Box className={classes.ticketInfo1}>
                                {renderTicketInfo()}
                            </Box>
                        </ListItemSecondaryAction>
                    </span>
                }
                secondary={
                    <Typography
                        className={classes.contactLastMessage}
                        noWrap
                        component="span"
                        variant="body2"
                        color="textSecondary">
                        {renderLastMessage()}

                        {!!tag?.length && (
                            <span
                                style={{ paddingTop: '2px' }}
                                className={classes.tags}>
                                {tag?.map(tag => {
                                    return (
                                        <ContactTag
                                            tag={tag}
                                            key={`ticket-contact-tag-${ticket.id}-${tag.id}`}
                                        />
                                    )
                                })}
                            </span>
                        )}
                    </Typography>
                }
            />

            <div className={classes.ticketInfo}>
                <Typography variant="body2" color="textSecondary">
                    <ScheduleIcon fontSize="inherit" />
                    <span>
                        {isSameDay(parseISO(ticket.updatedAt), new Date()) ? (
                            <>{format(parseISO(ticket.updatedAt), 'HH:mm')}</>
                        ) : (
                            <>
                                {format(
                                    parseISO(ticket.updatedAt),
                                    'dd/MM/yyyy',
                                )}
                            </>
                        )}
                    </span>
                </Typography>

                {ticketUser && (
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        noWrap
                        classes={{ root: classes.ticketUser }}>
                        <PersonIcon fontSize="inherit" />
                        <span>{ticketUser}</span>
                    </Typography>
                )}

                {ticket.queueId && (
                    <Typography variant="body2" color="textSecondary" noWrap>
                        <PeopleAltIcon
                            fontSize="inherit"
                            style={{
                                color: ticket.queue?.color || 'inherit',
                            }}
                        />
                        <span>{ticket.queue?.name}</span>
                    </Typography>
                )}

                <Typography variant="body2" color="textSecondary" noWrap>
                    <WhatsAppIcon
                        fontSize="inherit"
                        style={{ color: '#25d366' }}
                    />
                    <span>{ticket?.whatsapp?.name}</span>
                </Typography>
            </div>

            <ListItemSecondaryAction className={classes.actionButtons}>
                {['pending', 'bot'].includes(ticket.status) && (
                    <ButtonWithSpinner
                        component={IconButton}
                        style={{
                            color: 'green',
                        }}
                        size="small"
                        loading={loading}
                        onClick={e => {
                            e.stopPropagation()
                            handleAcepptTicket(ticket.id)
                        }}>
                        <Tooltip title={i18n.t('ticketsList.buttons.accept')}>
                            <PlayCircleFilledWhiteIcon />
                        </Tooltip>
                    </ButtonWithSpinner>
                )}
                {ticket.status !== 'closed' && (
                    <ButtonWithSpinner
                        component={IconButton}
                        style={{
                            color: 'red',
                        }}
                        size="small"
                        loading={loading}
                        onClick={e => {
                            e.stopPropagation()
                            setOpenFinish(true)
                        }}>
                        <Tooltip title={i18n.t('ticketsList.buttons.closed')}>
                            <DoneAllIcon />
                        </Tooltip>
                    </ButtonWithSpinner>
                )}
                {ticket.status === 'closed' && (
                    <ButtonWithSpinner
                        component={IconButton}
                        style={{
                            color: 'red',
                        }}
                        size="small"
                        loading={loading}
                        onClick={e => {
                            e.stopPropagation()
                            handleReopenTicket(ticket.id)
                        }}>
                        <Tooltip title={i18n.t('ticketsList.buttons.reopen')}>
                            <ReplayIcon />
                        </Tooltip>
                    </ButtonWithSpinner>
                )}
            </ListItemSecondaryAction>
        </ListItem>
    )
}

export default TicketListItemCustom
