import { Handle, Position } from 'reactflow'
import styles from '../../general.module.scss'
import CustomNodeBase from '../../components/CustomNodeBase'
import MarkdownWrapper from 'components/MarkdownWrapper'
import WifiIcon from '@material-ui/icons/Wifi'
import { useEffect, useState } from 'react'
import api from 'services/api'

function ConnectionNode(myNode) {
    const { data } = myNode
    const [connection, setConnection] = useState()

    useEffect(() => {
        api.get(`/whatsapp/${data.connection}`).then(({data}) => {
            setConnection(data.name)
        }).catch(() => {})
    }, [data.connection])

    return (
        <div>
            <Handle type="target" position={Position.Top} />
            <CustomNodeBase
                node={myNode}
                name="Transferir Conexão"
                icon={WifiIcon}>
                <div className={styles.blockList}>
                    <div className={styles.blockContent}>
                        {connection || 'Configure!'}
                    </div>
                </div>

                {data.message && (
                    <div className={styles.blockList}>
                        <span className={styles.blockTitle}>Mensagem:</span>
                        <div className={styles.blockContent}>
                            <MarkdownWrapper>{data.message}</MarkdownWrapper>
                        </div>
                    </div>
                )}
            </CustomNodeBase>
        </div>
    )
}

export default ConnectionNode
