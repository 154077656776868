import React from 'react';
import { useTheme } from "@material-ui/core/styles";
import { ResponsiveContainer, Legend, RadialBarChart, PolarAngleAxis, RadialBar, Tooltip } from 'recharts';

const renderLegend = (props) => {
    const { payload } = props;
  
    return (
      <ul
        style={{ 
            display: 'flex',
            listStyleType: 'none',
            flexDirection: 'column-reverse',
            position: 'relative',
            bottom: '84px',
            gap: '2px',
            right: '78px',
            textAlign: 'end'
        }} 
      >
        {
          payload.map((entry, index) => (
            <li key={`item-${index}`}>{entry.value}</li>
          ))
        }
      </ul>
    );
}

const renderTooltip = ({ payload }) => {
    if (!payload.length)
        return null
    
    return (
        <div 
            className='recharts-default-tooltip'
            style={{
                margin: '0px',
                padding: '10px',
                backgroundColor: 'rgb(255, 255, 255)',
                border: '1px solid rgb(204, 204, 204)',
                whiteSpace: 'nowrap'
            }}
        >
            {payload.at(0).payload.name}: {payload.at(0).payload.value}%
        </div>
    )
}

const parseValue = (value, total) => {
    const parsed = parseFloat((value || 0) * 100 / total).toFixed(2)
    return isNaN(parsed) ? 0 : parsed
}

export default function ChartCounters({ counters }) {
    const theme = useTheme();
    const { supportAll, supportBot, supportFinished, supportHappening, supportPending } = counters

    const data = [
        { name: 'Pendentes', value: parseValue(supportPending, supportAll) },
        { name: 'Em Atendimento', value: parseValue(supportHappening, supportAll) },
        { name: 'No Bot', value: parseValue(supportBot, supportAll) },
        { name: 'Finalizados', value: parseValue(supportFinished, supportAll) },
    ]

    return (
        <React.Fragment>
            <ResponsiveContainer debounce={500}>
                <RadialBarChart data={data} width={700} height={700} innerRadius={50} outerRadius={140} startAngle={90} endAngle={-180} fill={theme.palette.primary.main} >
                    <PolarAngleAxis type="number" domain={[0, 100]} angleAxisId={0} tick={false} />
                    <RadialBar background clockWise dataKey="value" cornerRadius={25} />
                    <Legend verticalAlign="middle" layout='vertical' content={renderLegend} />
                    <Tooltip cursor={{ strokeWidth: 0 }} content={renderTooltip} />
                </RadialBarChart>
            </ResponsiveContainer>
        </React.Fragment>
    );
}
