import React from "react";
import { Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import Title from "./Title";
import { Paper, Typography } from "@material-ui/core";
import useStyles from "./Style";

const colors = ["#284359","#FF3D00","#0ACC1A","#3A3A3A","#FF9B3D","#00B0B9","#9B30FF","#ADFF33"]
  
function getRandomColor(index) {
    return colors[index !== undefined ? index % 8 : parseInt(Math.random() * (7 - 1) + 1)]
}

const DashboardPieChart = ({ title, data, dataKey, formatter }) => {
    const classes = useStyles()

    const hasData = data && data.length
    const newData = !hasData ? data : data.map((current, index) => ({...current, fill: getRandomColor(index)})) 

	return (
        <Paper className={classes.fixedHeightPaper} style={hasData ? {height: 520} : {height: 120}}>
            {!!title && <Title>{title}</Title>}
            {!hasData ?
            <Typography component="h2" variant="h6" align="center">
                Sem informações
            </Typography>
            : <ResponsiveContainer>
                <PieChart width={600} height={520}>
                    <Legend align="right" verticalAlign="top" layout='vertical' margin={{ left: 160 }} />
                    <Tooltip formatter={formatter} />
                    <Pie
                        dataKey={dataKey}
                        data={newData}
                        cx={'50%'}
                        cy={'50%'}
                        innerRadius={170}
                        outerRadius={220}
                    />
                </PieChart>
            </ResponsiveContainer>}
        </Paper>
	);
};

export default DashboardPieChart;
