import { Handle, Position } from 'reactflow';
import CustomNodeBase from '../../components/CustomNodeBase'
import DoneIcon from '@material-ui/icons/Done'
import styles from '../../general.module.scss'
import MarkdownWrapper from 'components/MarkdownWrapper';

const TICKET_STATUSES = {
    closed: 'Finaliza Ticket',
    pending: 'Passa Ticket para Aguardando',
    open: 'Passa Ticket para Atendendo'
}

function FinishNode(myNode) {
    const { data } = myNode
    return (
        <div>
            <Handle type="target" position={Position.Top} />
            <CustomNodeBase node={myNode} name="Finalizar Bot" icon={DoneIcon}>
                <div className={styles.blockList}>
                    <div className={styles.blockContent}>
                        {!myNode.data.status
                            ? 'Finaliza Ticket'
                            : TICKET_STATUSES[myNode.data.status]}
                    </div>
                </div>

                {data.message && (
                    <div className={styles.blockList}>
                        <span className={styles.blockTitle}>Mensagem:</span>
                        <div className={styles.blockContent}>
                            <MarkdownWrapper>{data.message}</MarkdownWrapper>
                        </div>
                    </div>
                )}
            </CustomNodeBase>
        </div>
    )
}

export default FinishNode;
