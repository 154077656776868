import { makeStyles } from '@material-ui/core/styles'
import { grey, blue } from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    fixedHeightPaper: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        height: 320,
        overflowY: 'auto',
        ...theme.scrollbarStyles,
    },
    cardAvatar: {
        fontSize: '55px',
        color: grey[500],
        backgroundColor: '#ffffff',
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    cardTitle: {
        fontSize: '18px',
        color: blue[700],
    },
    cardSubtitle: {
        color: grey[600],
        fontSize: '14px',
    },
    alignRight: {
        textAlign: 'right',
    },
    fullWidth: {
        width: '100%',
    },
    selectContainer: {
        width: '100%',
        textAlign: 'left',
    },
    card: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        height: '100%',
        userSelect: 'none',
        cursor: 'pointer',
        backgroundColor: theme.mode === 'light' ? '#284359' : '#284359',
        color: '#eee',
    }
}))

export default useStyles