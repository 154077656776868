import React, { useState, useEffect, useContext, createRef } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import { toast } from 'react-toastify'
import clsx from 'clsx'

import { Paper, makeStyles } from '@material-ui/core'

import ContactDrawer from '../ContactDrawer'
import MessageInput from '../MessageInputCustom/'
import TicketHeader from '../TicketHeader'
import TicketInfo from '../TicketInfo'
import TicketActionButtons from '../TicketActionButtonsCustom'
import MessagesList from '../MessagesList'
import api from '../../services/api'
import { ReplyMessageProvider } from '../../context/ReplyingMessage/ReplyingMessageContext'
import toastError from '../../errors/toastError'
import { AuthContext } from '../../context/Auth/AuthContext'
import { TagsContainer } from '../TagsContainer'
import { SocketContext } from '../../context/Socket/SocketContext'

const drawerWidth = 320

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        height: '100%',
        position: 'relative',
        overflow: 'hidden',
    },

    mainWrapper: {
        flex: 1,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        borderRadius: '8px',
        borderLeft: '0',
        marginRight: -drawerWidth,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },

    mainWrapperShift: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
}))

const Ticket = ({ ticketId, ignoreEscape = false }) => {
    const params = useParams()

    if (!ticketId && params.ticketId) ticketId = params.ticketId

    const history = useHistory()
    const classes = useStyles()
    const socketManager = useContext(SocketContext)

    const { user } = useContext(AuthContext)

    const [drawerOpen, setDrawerOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [contact, setContact] = useState({})
    const [ticket, setTicket] = useState({})

    useEffect(() => {
        if (!ignoreEscape) {
            const handleKeyDown = event => {
                if (event.key === 'Escape') {
                    history.push('/tickets')
                }
            }

            window.addEventListener('keydown', handleKeyDown)

            return () => {
                window.removeEventListener('keydown', handleKeyDown)
            }
        }
    }, [history, ignoreEscape])

    useEffect(() => {
        setLoading(true)
        const delayDebounceFn = setTimeout(() => {
            const fetchTicket = async () => {
                try {
                    const { data } = await api.get('/tickets/u/' + ticketId)
                    const { queueId } = data
                    const { queues, profile } = user

                    const queueAllowed = queues.find(q => q.id === queueId)
                    if (queueAllowed === undefined && profile !== 'admin') {
                        toast.error('Acesso não permitido')
                        history.push('/tickets')
                        return
                    }

                    setContact(data.contact)
                    setTicket(data)
                    setLoading(false)
                } catch (err) {
                    setLoading(false)
                    toastError(err)
                }
            }
            fetchTicket()
        }, 500)
        return () => clearTimeout(delayDebounceFn)
    }, [ticketId, user, history])

    useEffect(() => {
        const companyId = localStorage.getItem('companyId')
        const socket = socketManager.GetSocket(companyId)

        socketManager.onConnect(() =>
            socket.emit('joinChatBox', `${ticket.id}`),
        )

        socket.on(`company-${companyId}-ticket`, data => {
            if (data.ticketId !== ticket.id) return

            if (data.action === 'update') {
                setTicket(data.ticket)
            }

            if (data.action === 'delete') {
                toast.success('Ação realizada com sucesso.')
                history.push('/tickets')
            }
        })

        socket.on(`company-${companyId}-contact`, data => {
            if (data.action === 'update') {
                setContact(prevState => {
                    if (prevState.id === data.contact?.id) {
                        return { ...prevState, ...data.contact }
                    }
                    return prevState
                })
            }
        })

        return () => {
            socket.disconnect()
        }
    }, [ticketId, ticket, history, socketManager])

    const handleDrawerOpen = () => {
        setDrawerOpen(true)
    }

    const handleDrawerClose = () => {
        setDrawerOpen(false)
    }

    const renderTicketInfo = () => {
        if (ticket.user !== undefined) {
            return (
                <TicketInfo
                    contact={contact}
                    ticket={ticket}
                    onClick={handleDrawerOpen}
                />
            )
        }
    }

    const renderMessagesList = () => {
        const childRef = createRef()

        const handleFocus = event => {
            // Se o clique não foi no background do ticket, ignoramos ele
            if (event.target !== event.currentTarget) return

            childRef.current.focus()
        }

        return (
            <>
                <MessagesList
                    ticket={ticket}
                    ticketId={ticket.id}
                    isGroup={ticket.isGroup}
                    backgroundClick={handleFocus}></MessagesList>
                <MessageInput
                    ticketId={ticket.id}
                    ticketStatus={ticket.status}
                    inputRef={childRef}
                />
            </>
        )
    }

    return (
        <div className={classes.root} id="drawer-container">
            <Paper
                variant="outlined"
                elevation={0}
                className={clsx(classes.mainWrapper, {
                    [classes.mainWrapperShift]: drawerOpen,
                })}>
                <TicketHeader loading={loading}>
                    {renderTicketInfo()}
                    <TicketActionButtons ticket={ticket} />
                </TicketHeader>
                <Paper>
                    <TagsContainer ticket={ticket} />
                </Paper>
                <ReplyMessageProvider>
                    {renderMessagesList()}
                </ReplyMessageProvider>
            </Paper>
            <ContactDrawer
                open={drawerOpen}
                handleDrawerClose={handleDrawerClose}
                contact={contact}
                loading={loading}
                ticket={ticket}
            />
        </div>
    )
}

export default Ticket
