import React from 'react'

import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import FormHelperText from '@material-ui/core/FormHelperText'

import { toast } from 'react-toastify'
import ButtonWithSpinner from '../../../components/ButtonWithSpinner'

import { isEmpty } from 'lodash'
import moment from 'moment'
import QueueSelect from 'components/QueueSelect'
import UserSelect from 'components/UserSelect'

import useStyles from './Style'

export default function Filter({
    onFilter = async () => {},
    setLoading = () => {},
    loading = false,
}) {
    const classes = useStyles()

    const [filterType, setFilterType] = React.useState('date')
    const [period, setPeriod] = React.useState(0)
    const [dateFrom, setDateFrom] = React.useState(moment('1', 'D').format('YYYY-MM-DD'))
    const [dateTo, setDateTo] = React.useState(moment().format('YYYY-MM-DD'))
    const [selectedUserIds, setSelectedUserIds] = React.useState([])
    const [selectedQueueIds, setSelectedQueueIds] = React.useState([])

    React.useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function handleChangePeriod(value) {
        setPeriod(value)
    }

    async function handleChangeFilterType(value) {
        setFilterType(value)
        if (value === 'date') {
            setPeriod(0)
        } else {
            setDateFrom('')
            setDateTo('')
        }
    }

    async function fetchData() {
        setLoading(true)

        let params = {
            users: selectedUserIds,
            queues: selectedQueueIds,
        }

        if (period > 0) {
            params = {
                days: period,
            }
        }

        if (!isEmpty(dateFrom) && moment(dateFrom).isValid()) {
            params = {
                ...params,
                date_from: moment(dateFrom).format('YYYY-MM-DD'),
            }
        }

        if (!isEmpty(dateTo) && moment(dateTo).isValid()) {
            params = {
                ...params,
                date_to: moment(dateTo).format('YYYY-MM-DD'),
            }
        }

        if (Object.keys(params).length === 0) {
            toast.error('Parametrize o filtro')
            setLoading(false)
            return
        }

        await onFilter(params)
        setLoading(false)
    }

    function renderFilters() {
        if (filterType === 'date') {
            return (
                <>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label="Data Inicial"
                            type="date"
                            value={dateFrom}
                            onChange={e => setDateFrom(e.target.value)}
                            className={classes.fullWidth}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label="Data Final"
                            type="date"
                            value={dateTo}
                            onChange={e => setDateTo(e.target.value)}
                            className={classes.fullWidth}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                </>
            )
        } else {
            return (
                <Grid item xs={12} sm={6} md={4}>
                    <FormControl className={classes.selectContainer}>
                        <InputLabel id="period-selector-label">
                            Período
                        </InputLabel>
                        <Select
                            labelId="period-selector-label"
                            id="period-selector"
                            value={period}
                            onChange={e => handleChangePeriod(e.target.value)}>
                            <MenuItem value={0}>Nenhum selecionado</MenuItem>
                            <MenuItem value={3}>Últimos 3 dias</MenuItem>
                            <MenuItem value={7}>Últimos 7 dias</MenuItem>
                            <MenuItem value={15}>Últimos 15 dias</MenuItem>
                            <MenuItem value={30}>Últimos 30 dias</MenuItem>
                            <MenuItem value={60}>Últimos 60 dias</MenuItem>
                            <MenuItem value={90}>Últimos 90 dias</MenuItem>
                        </Select>
                        <FormHelperText>
                            Selecione o período desejado
                        </FormHelperText>
                    </FormControl>
                </Grid>
            )
        }
    }

    return (
        <>
            <Grid item xs={12} sm={6} md={4}>
            <FormControl className={classes.selectContainer}>
                <InputLabel id="period-selector-label">
                    Tipo de Filtro
                </InputLabel>
                <Select
                    labelId="period-selector-label"
                    value={filterType}
                    onChange={e =>
                        handleChangeFilterType(e.target.value)
                    }>
                    <MenuItem value="date">
                        Filtro por Data
                    </MenuItem>
                    <MenuItem value="period">
                        Filtro por Período
                    </MenuItem>
                </Select>
                <FormHelperText>
                    Selecione o período desejado
                </FormHelperText>
            </FormControl>
        </Grid>

        {renderFilters()}

        <Grid item xs={12} sm={6} md={4}>
            <QueueSelect
                value={selectedQueueIds}
                onChange={setSelectedQueueIds}
            />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
            <UserSelect
                value={selectedUserIds}
                onChange={setSelectedUserIds}
                multiple
            />
        </Grid>

        <Grid item xs={12} sm={filterType === 'date' ? 6 : 12} md={filterType === 'date' ? 4 : 8} className={classes.alignRight}>
            <ButtonWithSpinner
                loading={loading}
                onClick={() => fetchData()}
                variant="contained"
                color="primary">
                Filtrar
            </ButtonWithSpinner>
        </Grid>
        </>
    )
}