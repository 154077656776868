import { Grid, TextField } from '@material-ui/core'
import { Field } from 'formik'
import MessageWithPicker from '../../components/MessageWithPicker'
import { Alert } from '@material-ui/lab'

const customVariables = [
    {
        name: 'Fatura ID',
        value: 'fatura id',
    },
    {
        name: 'Fatura Descrição',
        value: 'fatura descricao',
    },
    {
        name: 'Fatura Valor',
        value: 'fatura valor',
    },
    {
        name: 'Fatura Vencimento',
        value: 'fatura vencimento',
    },
    {
        name: 'Fatura Link',
        value: 'fatura link',
    },
    {
        name: 'Fatura Link Curto',
        value: 'fatura link curto',
    },
    {
        name: 'Imóvel Endereço',
        value: 'imovel endereco',
    },
]

const SendInvoiceForm = props => {
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Field
                    as={TextField}
                    variant="outlined"
                    fullWidth
                    name="title"
                    label="Título"
                    {...props.getFieldProps(`title`)}
                />
            </Grid>

            <Grid item xs={12}>
                <MessageWithPicker
                    name="message"
                    label="Mensagem"
                    {...props.getFieldProps(`message`)}
                    customVariables={customVariables}
                />
            </Grid>

            <Alert style={{ width: '100%' }} severity="info">
                Somente será enviada a fatura caso o Ticket tenha vinculado o
                registro de Pessoa do sistema Apresenta.me, senão o Bot irá
                pular essa etapa
            </Alert>
        </Grid>
    )
}

export default SendInvoiceForm
