import { Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab"
import Title from "pages/Dashboard/components/Title";
import DashTextItem from "../components/DashTextItem";

const QueueCards = ({ loading, queues }) => {

    if (loading)
        return <Skeleton variant="rect" height={160} />

    return (
        <>
            <Title>Setores: {queues.length}</Title>
            <Grid container spacing={1} justifyContent="center">
            {
                queues.map((queue, key) => (
                    <DashTextItem key={key} value={queue.users} label={'Usuários'} {...queue} />
                ))
            }
            </Grid>
        </>
    )
}

export default QueueCards