import { useField } from 'formik'
import ConnectionSelect from '.'

const FormikConnectionSelect = props => {
    const [field, , helpers] = useField(props.name)

    const handleChange = value => {
        helpers.setValue(value)
    }

    return <ConnectionSelect {...field} {...props} onChange={handleChange} />
}

export default FormikConnectionSelect
