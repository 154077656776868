import { Field } from 'formik'
import MessageWithPicker from 'pages/ChatFlow/components/MessageWithPicker'
import {
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'

const FinishForm = props => {
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Field
                    as={TextField}
                    variant="outlined"
                    fullWidth
                    name="title"
                    label="Título"
                    {...props.getFieldProps(`title`)}
                />
            </Grid>

            <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="status-select">Status</InputLabel>

                    <Field
                        as={Select}
                        name="status"
                        label="Status"
                        id="status-select"
                        multiple={false}
                        {...props.getFieldProps(`status`)}
                        default="closed">
                        <MenuItem value="closed">Finalizar</MenuItem>
                        <MenuItem value="pending">Aguardando</MenuItem>
                        <MenuItem value="open">Atendendo</MenuItem>
                    </Field>
                </FormControl>
            </Grid>

            <Alert style={{ width: '100%' }} severity="info">
                Se o campo "Status" estiver vazio, o ticket será{' '}
                <b>finalizado</b>.
            </Alert>
            <Alert style={{ width: '100%', marginTop: 2 }} severity="info">
                Os status de <b>Aguardando</b> e de <b>Atendendo</b> só serão
                aplicados caso tenha algum Setor ou Atendente definido, senão o
                atendimento voltará para o início do bot.
            </Alert>

            <Grid item xs={12}>
                <hr style={{ borderStyle: 'dashed', borderColor: '#b9b9b9' }} />
            </Grid>

            <Grid item xs={12}>
                <MessageWithPicker
                    name="message"
                    label="Mensagem"
                    {...props.getFieldProps(`message`)}
                />
            </Grid>
        </Grid>
    )
}

export default FinishForm
