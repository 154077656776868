import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Bar, BarChart, CartesianGrid, Label, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import Title from "./Title";
import { Paper, Typography } from "@material-ui/core";
import useStyles from "./Style";

const colors = ["#284359","#FF3D00","#0ACC1A","#3A3A3A","#FF9B3D","#00B0B9","#9B30FF","#ADFF33"]
  
function getRandomColor(index) {
    return colors[index !== undefined ? index % 8 : parseInt(Math.random() * (7 - 1) + 1)]
}

const useStylesChart = makeStyles(theme => ({
    recharts: {
        "& > svg.recharts-surface": {
            width: '99% !important'
        }
    },
}));

const DashboardBarChart = ({ title, label, data, formatter, dataKey, xKey }) => {
    const classesChart = useStylesChart()
    const classes = useStyles()
    const theme = useTheme()

    const hasData = data && data.length
    const newData = !hasData ? data : data.map((current, index) => ({...current, fill: getRandomColor(index)}))
    const legendPayload = !hasData ? [] : newData.map(current => ({value: current[xKey], type: 'square', color: current.fill}))

	return (
        <Paper className={classes.fixedHeightPaper} style={hasData ? {} : {height: 120}}>
            {!!title && <Title>{title}</Title>}
            {!hasData ?
            <Typography component="h2" variant="h6" align="center">
                Sem informações
            </Typography>
            : <ResponsiveContainer>
                <BarChart
                    data={newData}
                    barSize={40}
                    width={730}
                    height={250}
                    className={classesChart.recharts}
                    margin={{ top: 16, right: 16, bottom: 0, left: 24 }}
                >
                    <CartesianGrid />
                    <XAxis dataKey={xKey} stroke={theme.palette.text.secondary} />
                    <YAxis type="number" allowDecimals={false} stroke={theme.palette.text.secondary}>
                        <Label angle={270} position="left" style={{ textAnchor: "middle", fill: theme.palette.text.primary }}>
                            {label}
                        </Label>
                    </YAxis>
                    <Legend payload={legendPayload} align="right" verticalAlign="top" layout='vertical' margin={{ left: 160 }} />
                    <Tooltip formatter={formatter} />
                    <Bar dataKey={dataKey} />
                </BarChart>
            </ResponsiveContainer>}
        </Paper>
	);
};

export default DashboardBarChart;
