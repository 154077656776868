import React from 'react'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

import Filter from '../components/Filter'
import useStyles from '../components/Style'
import DashboardBarChart from '../components/DashboardBarChart'
import DashboardPieChart from '../components/DashboardPieChart'
import useDashboard from 'hooks/useDashboard'
import DashTextItem from '../components/DashTextItem'

const Metrics = () => {
    const classes = useStyles()

    const { findMetricsDashboard } = useDashboard()

    const [data, setData] = React.useState({})
    const [loading, setLoading] = React.useState(false)
    
    async function onFilter(params) {
        const data = await findMetricsDashboard(params)
        setData(data)
    }

    const counters = data.counters ?? {}

    return (
        <div>
            <Container maxWidth="xl" className={classes.container}>
                <Grid container spacing={2} justifyContent="flex-start">
                    <Filter onFilter={onFilter} setLoading={setLoading} loading={loading} />

                    <Grid container spacing={2} style={{ justifyContent: "space-evenly", padding: '1rem 0' }}>

                        <DashTextItem reverse value={counters.ticketTotalMinutes} label={'minutos'} name={'Tempo de Atendimento'} />

                        <DashTextItem reverse value={counters.ticketTotalMessages} label={'mensagens'} name={'Total de Mensagens'} />

                        <DashTextItem reverse value={counters.leads} label={'contatos'} name={'Novos Contatos'} />

                        <DashTextItem reverse value={counters.supportHappening} label={'atendimentos'} name={'Atendimentos Abertos'} />

                        <DashTextItem reverse value={counters.supportFinished} label={'atendimentos'} name={'Atendimentos Finalizados'} />

                    </Grid>

                    <Grid item xs={12}>
                        <DashboardBarChart
                            title="Ranking de Mensagens Trocadas por Atendente" 
                            label="Mensagens Trocadas" 
                            xKey="name" 
                            dataKey="messages" 
                            data={data.usersMessages} 
                            formatter={(value, name, props) => [value, 'Mensagens']} 
                        />
                    </Grid>
                    
                    <Grid item xs={12}>
                        <DashboardBarChart
                            title="Ranking de Atendimentos Atendidos" 
                            label="Atendimentos" 
                            xKey="name" 
                            dataKey="tickets" 
                            data={data.usersTickets} 
                            formatter={(value, name, props) => [value, 'Atendimentos']} 
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <DashboardPieChart 
                            title="Ranking de Clientes que mais trocaram Mensagens"
                            formatter={(value, name) => [`${value} Mensagens`, name]}
                            data={data.clientMessages}
                            dataKey="messages"
                        />
                    </Grid>

                </Grid>
            </Container>
        </div>
    )
}

export default Metrics
