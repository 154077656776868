import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import { makeStyles, createTheme, ThemeProvider } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import { MoreVert, Replay } from "@material-ui/icons";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import TicketOptionsMenu from "../TicketOptionsMenu";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { TicketsContext } from "../../context/Tickets/TicketsContext";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import UndoRoundedIcon from '@material-ui/icons/UndoRounded';
import Tooltip from '@material-ui/core/Tooltip';
import { green } from '@material-ui/core/colors';
import CloseTicketModal from "components/CloseTicketModal";


const useStyles = makeStyles(theme => ({
	actionButtons: {
		marginRight: 6,
		flex: "none",
		alignSelf: "center",
		marginLeft: "auto",
		"& > *": {
			margin: theme.spacing(0.5),
		},
	},
}));

const TicketActionButtonsCustom = ({ ticket }) => {
	const classes = useStyles();
	const history = useHistory();
	const [anchorEl, setAnchorEl] = useState(null);
	const [loading, setLoading] = useState(false);
	const [openFinish, setOpenFinish] = useState(false)
	const ticketOptionsMenuOpen = Boolean(anchorEl);
	const { user } = useContext(AuthContext);
	const { setCurrentTicket } = useContext(TicketsContext);

	const customTheme = createTheme({
		palette: {
		  	primary: green,
		}
	});

	const handleOpenTicketOptionsMenu = e => {
		setAnchorEl(e.currentTarget);
	};

	const handleCloseTicketOptionsMenu = e => {
		setAnchorEl(null);
	};

	const handleUpdateTicketStatus = async (e, status, userId) => {
        if (status === 'closed')
            return setOpenFinish(true)

		setLoading(true);
		try {
			await api.put(`/tickets/${ticket.id}`, {
                status: status,
                userId: userId || null,
                flowId: null,
                step: null,
            })

			setLoading(false);
			if (status === "open") {
				setCurrentTicket({ ...ticket, code: "#open" });
			} else {
				setCurrentTicket({ id: null, code: null })
				history.push("/tickets");
			}
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
	};

	return (
        <div className={classes.actionButtons}>
            <CloseTicketModal ticket={ticket} open={openFinish} onClose={() => setOpenFinish(false)} />

            {ticket.status === 'closed' && (
                <ButtonWithSpinner
                    loading={loading}
                    startIcon={<Replay />}
                    size="small"
                    onClick={e =>
                        handleUpdateTicketStatus(e, 'open', user?.id)
                    }>
                    {i18n.t('messagesList.header.buttons.reopen')}
                </ButtonWithSpinner>
            )}
            {ticket.status === 'open' && (
                <>
                    <Tooltip
                        title={i18n.t('messagesList.header.buttons.return')}>
                        <IconButton
                            onClick={e =>
                                handleUpdateTicketStatus(e, 'pending', null)
                            }>
                            <UndoRoundedIcon />
                        </IconButton>
                    </Tooltip>
                    <ThemeProvider theme={customTheme}>
                        <Tooltip
                            title={i18n.t(
                                'messagesList.header.buttons.resolve',
                            )}>
                            <IconButton
                                onClick={e =>
                                    handleUpdateTicketStatus(
                                        e,
                                        'closed',
                                        user?.id,
                                    )
                                }
                                color="primary">
                                <CheckCircleIcon />
                            </IconButton>
                        </Tooltip>
                    </ThemeProvider>
                    <IconButton onClick={handleOpenTicketOptionsMenu}>
                        <MoreVert />
                    </IconButton>
                    <TicketOptionsMenu
                        ticket={ticket}
                        anchorEl={anchorEl}
                        menuOpen={ticketOptionsMenuOpen}
                        handleClose={handleCloseTicketOptionsMenu}
                    />
                </>
            )}
            {['pending', 'bot'].includes(ticket.status) && (
                <ButtonWithSpinner
                    loading={loading}
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={e =>
                        handleUpdateTicketStatus(e, 'open', user?.id)
                    }>
                    {i18n.t('messagesList.header.buttons.accept')}
                </ButtonWithSpinner>
            )}
        </div>
    )
};

export default TicketActionButtonsCustom;
