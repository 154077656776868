import { Handle, Position } from 'reactflow'
import CustomStartHandle from '../../components/CustomStartHandle'
import styles from '../../general.module.scss'
import CustomNodeBase from '../../components/CustomNodeBase'
import MarkdownWrapper from 'components/MarkdownWrapper'
import WifiIcon from '@material-ui/icons/Wifi'

function HttpNode(myNode) {
    const { data, ...props } = myNode

    return (
        <div>
            <Handle type="target" position={Position.Top} />
            <CustomNodeBase
                node={myNode}
                name="Requisição HTTP"
                icon={WifiIcon}>
                <div className={styles.blockList}>
                    <div className={styles.blockContent}>
                        {
                            data.request && data.url ? 
                            `${data.request?.toUpperCase()} para ${data.url}`
                            :  'Configure!'
                        }
                    </div>
                </div>

                {data.message && (
                    <div className={styles.blockList}>
                        <span className={styles.blockTitle}>Mensagem:</span>
                        <div className={styles.blockContent}>
                            <MarkdownWrapper>{data.message}</MarkdownWrapper>
                        </div>
                    </div>
                )}
            </CustomNodeBase>
            <CustomStartHandle position={Position.Bottom} nodeId={props.id} />
        </div>
    )
}

export default HttpNode
