import React from 'react'
import General from './General'
import { Tab, Tabs } from '@material-ui/core'
import TabPanel from 'components/TabPanel'
import Tickets from './Tickets'
import Metrics from './Metricts'

const Dashboard = () => {
    const [tab, setTab] = React.useState('general')
    
    return (
        <div>
            <Tabs
                value={tab}
                onChange={(e, newValue) => setTab(newValue)}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons='off'
            >
                <Tab label='Geral' value='general' />
                <Tab label='Atendimentos' value='tickets' />
                <Tab label='Métricas' value='metrics' />
            </Tabs>
            <TabPanel value={tab} name="general">
                <General />
            </TabPanel>
            <TabPanel value={tab} name="tickets">
                <Tickets />
            </TabPanel>
            <TabPanel value={tab} name="metrics">
                <Metrics />
            </TabPanel>
        </div>
    )
}

export default Dashboard
