import { ListItemText, MenuItem, Select, Typography } from '@material-ui/core'
import { AuthContext } from 'context/Auth/AuthContext'
import { useContext, useEffect, useState } from 'react'
import api from 'services/api'

const ConnectionSelect = ({ value, onChange, ...props }) => {
    const [whatsapps, setWhatsapps] = useState([])
    const { user } = useContext(AuthContext)
    const { companyId, whatsappId } = user

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (whatsappId !== null && whatsappId !== undefined)
                onChange(whatsappId)

            api.get(`/whatsapp`, { companyId, session: 0 }).then(({ data }) =>
                setWhatsapps(data),
            )
        }, 500)
        return () => clearTimeout(delayDebounceFn)
    }, [companyId, user, whatsappId, onChange])

    return (
        <Select
            fullWidth
            displayEmpty
            variant="outlined"
            value={value}
            onChange={e => {
                onChange(e.target.value)
            }}
            MenuProps={{
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                },
                getContentAnchorEl: null,
            }}
            renderValue={() => {
                if (value === '') return 'Selecione uma Conexão'

                return whatsapps.find(w => w.id === value)?.name
            }}>
            {whatsapps?.length > 0 &&
                whatsapps.map((whatsapp, key) => (
                    <MenuItem dense key={key} value={whatsapp.id}>
                        <ListItemText
                            primary={
                                <>
                                    <Typography
                                        component="span"
                                        style={{
                                            fontSize: 14,
                                            marginLeft: '10px',
                                            display: 'inline-flex',
                                            alignItems: 'center',
                                            lineHeight: '2',
                                        }}>
                                        {whatsapp.name} &nbsp;{' '}
                                        <p>({whatsapp.status})</p>
                                    </Typography>
                                </>
                            }
                        />
                    </MenuItem>
                ))}
        </Select>
    )
}

export default ConnectionSelect
