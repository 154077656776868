import { Button, Dialog, DialogActions, DialogTitle, Tooltip } from '@material-ui/core'
import { AuthContext } from 'context/Auth/AuthContext'
import toastError from 'errors/toastError'
import useSettings from 'hooks/useSettings'
import useWhatsApps from 'hooks/useWhatsApps'
import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import api from 'services/api'

export default function CloseTicketModal({ open, onClose, ticket }) {
    const history = useHistory()
    const { user } = useContext(AuthContext)
    const { getAll } = useSettings()
    const { whatsApps } = useWhatsApps()
    const [settings, setSettings] = useState({})

    useEffect(() => {
        if (!open) return

        getAll().then(savedSettings => {
            const newSettings = {}
            savedSettings.map(
                setting =>
                    (newSettings[setting.key] = setting.value === 'enabled'),
            )
            newSettings.ratingMessage = !['', null, undefined].includes(
                whatsApps?.find(wpp => wpp.id === ticket.whatsappId)?.ratingMessage
            )
            newSettings.completionMessage = !['', null, undefined].includes(
                whatsApps?.find(wpp => wpp.id === ticket.whatsappId)?.completionMessage
            )

            setSettings(newSettings)
        })

        // eslint-disable-next-line
    }, [open, ticket, whatsApps])

    const handleCloseTicket = async type => {
        const payload = {
            status: 'closed',
            userId: user?.id,
        }

        switch (type) {
            case 'evaluate':
                break
            case 'conclude':
                payload.dontSendEvaluation = true
                break
            case 'finish':
                payload.dontSendEvaluation = true
                payload.dontSendCompletion = true
                break
            default:
        }

        try {
            await api.put(`/tickets/${ticket.id}`, payload)
        } catch (err) {
            toastError(err)
        }

        history.push(`/tickets/`)
        onClose()
    }

    const canShowEvaluate = ticket.userId && settings.ratingMessage
    const canShowConclude = settings.completionMessage    

    return (
        <Dialog
            open={Boolean(open && Object.keys(settings).length)}
            onClose={onClose}
            maxWidth="md">
            <DialogTitle>
                {canShowEvaluate || canShowConclude ? 'Como você deseja finalizar' : 'Finalizar'} o atendimento?
            </DialogTitle>
            <DialogActions>
                {canShowEvaluate && (
                    <Tooltip
                        title="Finaliza e envia a mensagem de avaliação do atendimento"
                        placement="top"
                        arrow>
                        <Button
                            onClick={() => handleCloseTicket('evaluate')}
                            color="primary"
                            autoFocus>
                            Finalizar e Avaliar
                        </Button>
                    </Tooltip>
                )}
                {canShowConclude && (
                    <Tooltip
                        title="Finaliza e envia a mensagem de conclusão"
                        placement="top"
                        arrow>
                        <Button
                            onClick={() => handleCloseTicket('conclude')}
                            color="primary">
                            Finalizar com conclusão
                        </Button>
                    </Tooltip>
                )}
                <Tooltip
                    title="Finaliza sem enviar nenhuma mensagem"
                    placement="top"
                    arrow>
                    <Button
                        onClick={() => handleCloseTicket('finish')}
                        color="primary">
                        {canShowEvaluate || canShowConclude ? 'Apenas Finalizar' : 'Finalizar'}
                    </Button>
                </Tooltip>
                <Button onClick={onClose} color="secondary">
                    Cancelar
                </Button>
            </DialogActions>
        </Dialog>
    )
}
