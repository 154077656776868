import Route from './Route'
import LoggedInLayout from '../layout'

import Dashboard from '../pages/Dashboard/'
import TicketResponsiveContainer from '../pages/TicketResponsiveContainer'
import QueueIntegration from '../pages/QueueIntegration'
import Connections from '../pages/Connections/'
import SettingsCustom from '../pages/SettingsCustom/'
import Users from '../pages/Users'
import Contacts from '../pages/Contacts/'
import Queues from '../pages/Queues/'
import Tags from '../pages/Tags/'
import MessagesAPI from '../pages/MessagesAPI/'
import QuickMessages from '../pages/QuickMessages/'
import ChatFlow from '../pages/ChatFlow'
import ChatFlowList from '../pages/ChatFlowList'
import CustomField from '../pages/CustomField'
import OpenTicket from '../pages/OpenTicket'

const General = () => {
    return (
        <LoggedInLayout>
            <Route exact path="/" component={Dashboard} isPrivate />
            <Route
                exact
                path="/tickets/:ticketId?"
                component={TicketResponsiveContainer}
                isPrivate
            />
            <Route
                exact
                path="/connections"
                component={Connections}
                isPrivate
            />
            <Route
                exact
                path="/quick-messages"
                component={QuickMessages}
                isPrivate
            />
            <Route exact path="/tags" component={Tags} isPrivate />
            <Route exact path="/contacts" component={Contacts} isPrivate />
            <Route
                exact
                path="/custom-field"
                component={CustomField}
                isPrivate
            />
            <Route exact path="/users" component={Users} isPrivate />
            <Route
                exact
                path="/queue-integration"
                component={QueueIntegration}
                isPrivate
            />
            <Route
                exact
                path="/chat-flows"
                component={ChatFlowList}
                isPrivate
            />
            <Route
                exact
                path="/chat-flow/:flowId?"
                component={ChatFlow}
                isPrivate
            />

            <Route
                exact
                path="/messages-api"
                component={MessagesAPI}
                isPrivate
            />
            <Route
                exact
                path="/settings"
                component={SettingsCustom}
                isPrivate
            />
            <Route exact path="/queues" component={Queues} isPrivate />
            <Route
                exact
                path="/:number(\+?\d+)"
                component={OpenTicket}
                isPrivate
            />
        </LoggedInLayout>
    )
}

export default General
