import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import useStyles from './Style';

const DashItem = ({ xs = 12, sm = 6, md = 4, lg = 2, title, value, icon, ...props }) => {
    const classes = useStyles()

    return (
        <Grid item xs={xs} sm={sm} md={md} lg={lg}>
            <Paper
                className={classes.card}
                style={{ overflow: 'hidden', borderRadius: 16 }}
                elevation={6}
                {...props}
            >
                <Grid container style={{ justifyContent: 'center', alignItems: 'center' }}>
                    <Grid item xs={3} style={{ maxWidth: 'unset' }}>
                        <Typography component="h1" variant="h4" paragraph style={{ textAlign: 'center', fontSize: '4rem', marginBottom: 0, }}>
                            {value}
                        </Typography>
                    </Grid>
                    <Grid item xs={3} style={{ textAlign: 'center' }}>
                        {icon}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography component="h3" variant="h6" paragraph style={{ textAlign: 'center', marginBottom: 0 }}>
                            {title}
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )
}

export default DashItem