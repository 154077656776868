import React from 'react'

import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

// ICONS
import CallIcon from '@material-ui/icons/Call'
import AddIcon from '@material-ui/icons/Add'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import MessageIcon from '@material-ui/icons/Message'
import AndroidIcon from '@material-ui/icons/Android';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined'
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined'
import SyncAltIcon from '@material-ui/icons/SyncAlt'
import ForumOutlinedIcon from '@material-ui/icons/ForumOutlined'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import LinearScaleIcon from '@material-ui/icons/LinearScale'


import TableAttendantsStatus from './TableAttendantsStatus'
import { isArray } from 'lodash'

import useDashboard from 'hooks/useDashboard'

import useStyles from '../components/Style'
import DashItem from '../components/DashItem'
import QueueCards from './QueueCards'
import ChartCounters from './ChartCounters'
import Filter from '../components/Filter'
import ChartAttendants from '../Tickets/ChartAttendants'
import { useHistory } from 'react-router-dom'

const General = () => {
    const classes = useStyles()
    const history = useHistory()
    const { find } = useDashboard()
    
    const [data, setData] = React.useState({})
    const [loading, setLoading] = React.useState(false)
    
    async function onFilter(params) {
        const data = await find(params)
        setData({
            ...(data ?? {}),
            attendants: isArray(data.attendants) ? data.attendants : []
        })
    }

    const queues = data.queues ?? []
    const planUse = data.planUse ?? []
    const counters = data.counters ?? {}
    const attendants = data.attendants ?? []

    return (
        <div>
            <Container maxWidth="xl" className={classes.container}>
                <Grid container spacing={2} justifyContent="flex-start">
                    <Filter onFilter={onFilter} setLoading={setLoading} loading={loading} />
                    
                    <DashItem
                        title="Pendentes"
                        value={counters.supportPending}
                        onClick={() => history.push('/tickets/?tab=open&status=pending')}
                        icon={
                            <CallIcon
                                style={{
                                    fontSize: 64,
                                    color: '#FFFFFF',
                                    opacity: 0.5
                                }}
                            />
                        }
                    />

                    <DashItem
                        title="Em Atendimento"
                        value={counters.supportHappening}
                        onClick={() => history.push('/tickets/?tab=open&status=open')}
                        icon={
                            <HourglassEmptyIcon
                                style={{
                                    fontSize: 64,
                                    color: '#FFFFFF',
                                    opacity: 0.5
                                }}
                            />
                        }
                    />

                    <DashItem
                        title="No Bot"
                        value={counters.supportBot}
                        onClick={() => history.push('/tickets/?tab=open&status=bot')}
                        icon={
                            <AndroidIcon
                                style={{
                                    fontSize: 64,
                                    color: '#FFFFFF',
                                    opacity: 0.5
                                }}
                            />
                        }
                    />

                    <DashItem
                        title="Finalizados"
                        value={counters.supportFinished}
                        onClick={() => history.push('/tickets/?tab=closed')}
                        icon={
                            <CheckCircleIcon
                                style={{
                                    fontSize: 64,
                                    color: '#FFFFFF',
                                    opacity: 0.5
                                }}
                            />
                        }
                    />

                    <DashItem
                        title="Total"
                        value={counters.supportAll}
                        onClick={() => history.push('/tickets/')}
                        icon={
                            <MessageIcon
                                style={{
                                    fontSize: 64,
                                    color: '#FFFFFF',
                                    opacity: 0.5
                                }}
                            />
                        }
                    />

                    <DashItem
                        title="Novos Contatos"
                        value={counters.leads}
                        onClick={() => history.push('/contacts/')}
                        icon={
                            <AddIcon
                                style={{
                                    fontSize: 64,
                                    color: '#FFFFFF',
                                    opacity: 0.5
                                }}
                            />
                        }
                    />

                    <Grid item xs={12} md={4} xl={3} >
                        <Paper className={classes.fixedHeightPaper}>
                            <ChartCounters counters={counters} />
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={8} xl={9} >
                        <Paper className={classes.fixedHeightPaper}>
                            <ChartAttendants />
                        </Paper>
                    </Grid>

                    <Grid container spacing={2} justifyContent="center">
                        <DashItem
                            xs={null} sm={null} md={null} lg={null}
                            onClick={() => history.push('/users/')}
                            title="Usuários"
                            value={planUse.users}
                            icon={
                                <PeopleAltOutlinedIcon
                                    style={{
                                        fontSize: 64,
                                        color: '#FFFFFF',
                                        opacity: 0.5
                                    }}
                                />
                            }
                        />

                        <DashItem
                            xs={null} sm={null} md={null} lg={null}
                            onClick={() => history.push('/queues/')}
                            title="Setores"
                            value={planUse.queues}
                            icon={
                                <AccountTreeOutlinedIcon
                                    style={{
                                        fontSize: 64,
                                        color: '#FFFFFF',
                                        opacity: 0.5
                                    }}
                                />
                            }
                        />

                        <DashItem
                            xs={null} sm={null} md={null} lg={null}
                            onClick={() => history.push('/connections/')}
                            title="Conexões"
                            value={planUse.connections}
                            icon={
                                <SyncAltIcon
                                    style={{
                                        fontSize: 64,
                                        color: '#FFFFFF',
                                        opacity: 0.5
                                    }}
                                />
                            }
                        />

                        <DashItem
                            xs={null} sm={null} md={null} lg={null}
                            onClick={() => history.push('/tickets/')}
                            title="Mensagens"
                            value={planUse.messages}
                            icon={
                                <ForumOutlinedIcon
                                    style={{
                                        fontSize: 64,
                                        color: '#FFFFFF',
                                        opacity: 0.5
                                    }}
                                />
                            }
                        />

                        <DashItem
                            xs={null} sm={null} md={null} lg={null}
                            onClick={() => history.push('/tickets/?tab=group')}
                            title="Grupos"
                            value={planUse.groups}
                            icon={
                                <SupervisorAccountIcon
                                    style={{
                                        fontSize: 64,
                                        color: '#FFFFFF',
                                        opacity: 0.5
                                    }}
                                />
                            }
                        />

                        <DashItem
                            xs={null} sm={null} md={null} lg={null}
                            onClick={() => history.push('/tags/')}
                            title="Tags"
                            value={planUse.tags}
                            icon={
                                <LocalOfferIcon
                                    style={{
                                        fontSize: 64,
                                        color: '#FFFFFF',
                                        opacity: 0.5
                                    }}
                                />
                            }
                        />

                        <DashItem
                            xs={null} sm={null} md={null} lg={null}
                            onClick={() => history.push('/chat-flows/')}
                            title="Bots"
                            value={planUse.chatFlows}
                            icon={
                                <LinearScaleIcon
                                    style={{
                                        fontSize: 64,
                                        color: '#FFFFFF',
                                        opacity: 0.5
                                    }}
                                />
                            }
                        />
                    </Grid>

                    <Grid item xs={12}>
                        {attendants.length ? (
                            <TableAttendantsStatus
                                attendants={attendants}
                                loading={loading}
                            />
                        ) : null}
                    </Grid>

                    <Grid item xs={12}>
                        {queues.length ? (
                            <QueueCards
                                queues={queues}
                                loading={loading}
                            />
                        ) : null}
                    </Grid>

                </Grid>
            </Container>
        </div>
    )
}

export default General
