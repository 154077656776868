import { Field } from 'formik'
import MessageWithPicker from 'pages/ChatFlow/components/MessageWithPicker'
import { Grid, TextField } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import FormikConnectionSelect from 'components/ConnectionSelect/FormikConnectionSelect'

const ConnectionForm = props => {
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Field
                    as={TextField}
                    variant="outlined"
                    fullWidth
                    name="title"
                    label="Título"
                    {...props.getFieldProps(`title`)}
                />
            </Grid>

            <Grid item xs={12}>
                <FormikConnectionSelect name="connection" />
            </Grid>

            <Grid item xs={12}>
                <hr style={{ borderStyle: 'dashed', borderColor: '#b9b9b9' }} />
            </Grid>

            <Grid item xs={12}>
                <MessageWithPicker
                    name="message"
                    label="Mensagem"
                    {...props.getFieldProps(`message`)}
                />
            </Grid>

            <Alert style={{ width: '100%' }} severity="info">
                Ao transferir de conexão, o chatbot é finalizado automaticamente
            </Alert>
        </Grid>
    )
}

export default ConnectionForm
