import { useCallback } from 'react'
import { Field } from 'formik'
import MessageWithPicker from 'pages/ChatFlow/components/MessageWithPicker'
import { cloneDeep } from 'lodash'
import {
    Button,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { Alert } from '@material-ui/lab'

const RenderHeader = ({onRemove, item,...props}) => {
    return (
        <span style={{ display: 'flex' }}>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <Field
                        as={TextField}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        name={`headers[${item}].key`}
                        label="Chave"                        
                        value={props.values.headers[item]?.key}
                        onChange={ev =>
                            props.setFieldValue(
                                `headers[${item}].key`,
                                ev.target.value,
                            )
                        }
                    />
                </Grid>
                <Grid item xs={6}>
                    <Field
                        as={TextField}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        name={`headers[${item}].value`}
                        label="Valor"
                        value={props.values.headers[item]?.value}
                        onChange={ev =>
                            props.setFieldValue(
                                `headers[${item}].value`,
                                ev.target.value,
                            )
                        }
                    />
                </Grid>
            </Grid>

            <IconButton onClick={onRemove}>
                <DeleteIcon />
            </IconButton>
        </span>
    )
}

const HttpForm = props => {
    const addHeader = useCallback(() => {
        const newValues = cloneDeep(props.values)
        if (!newValues.headers?.length) newValues.headers = []
        newValues.headers.push({ key: '', value: '' })

        props.setValues(newValues)
    }, [props])

    const removeHeader = index => {
        const newValues = cloneDeep(props.values)
        newValues.headers.splice(index, 1) // Criado clone para não alterar o data por referencia

        props.setValues(newValues)
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Field
                    as={TextField}
                    variant="outlined"
                    fullWidth
                    name="title"
                    label="Título"
                    {...props.getFieldProps(`title`)}
                />
            </Grid>
            <Grid item xs={3}>
                <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="request">Tipo</InputLabel>
                    <Field
                        as={Select}
                        id="request"
                        label="Tipo"
                        labelId="request-label"
                        name="request"
                        {...props.getFieldProps(`request`)}
                        required
                        error={props.touched?.request && props.errors?.request}
                        helperText={
                            props.touched?.request && props.errors?.request
                        }>
                        <MenuItem value="get">GET</MenuItem>
                        <MenuItem value="post">POST</MenuItem>
                        <MenuItem value="put">PUT</MenuItem>
                        <MenuItem value="delete">DELETE</MenuItem>
                    </Field>
                </FormControl>
            </Grid>
            <Grid item xs={9}>
                <Field
                    as={TextField}
                    variant="outlined"
                    fullWidth
                    name="url"
                    label="URL"
                    {...props.getFieldProps(`url`)}
                    error={props.touched?.url && props.errors?.url}
                    helperText={props.touched?.url && props.errors?.url}
                />
            </Grid>

            <Grid item xs={12}>
                <hr style={{ borderStyle: 'dashed', borderColor: '#b9b9b9' }} />
            </Grid>

            <Button size="small" onClick={addHeader} variant="contained">
                Adicionar Header
            </Button>

            <Grid item xs={12}>
                {props.values.headers?.map((_, index) => {
                    return (
                        <RenderHeader
                            item={index}
                            key={index}
                            onRemove={() => removeHeader(index)}
                            {...props}
                        />
                    )
                })}
            </Grid>

            <Grid item xs={12}>
                <hr style={{ borderStyle: 'dashed', borderColor: '#b9b9b9' }} />
            </Grid>

            <Grid item xs={12}>
                <MessageWithPicker
                    name="message"
                    label="Mensagem"
                    {...props.getFieldProps(`message`)}
                />
            </Grid>

            <Alert style={{ width: '100%' }} severity="info">
                Serão enviados os dados do Ticket e do Contato por completo
            </Alert>
        </Grid>
    )
}

export default HttpForm
