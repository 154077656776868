import { Box, Chip, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import React, { useCallback, useEffect, useState } from 'react'
import api from '../../services/api'
import { uniq } from 'lodash'

export function UsersFilter({ onChange, value }) {
    const [open, setOpen] = useState(false)
    const [options, setOptions] = useState([])
    const [labelOptions, setLabelOptions] = useState([])
    const loading = open && options.length === 0

    useEffect(() => {
        if (!value?.length) return

        const getLabel = async users => {
            if (!users) return

            return (await api.get(`/users`, { filters: { idIn: users } })).data
                .data
        }

        new Promise(async resolve => {
            const labels = await getLabel(value)
            setLabelOptions(labels)
            resolve(true)
        })
    }, [value])

    useEffect(() => {
        let active = true
        if (!loading) return undefined

        api.get('/users').then(r => {
            if (active)
                setOptions(r.data.data)
        })

        return () => (active = false)
    }, [loading])

    useEffect(() => {
        if (!open) setOptions([])
    }, [open])

    const handleChange = useCallback(
        (e, value) => {
            setLabelOptions(uniq(value, 'id'))
            if (!value) return onChange(null)

            return onChange(uniq(value, 'id').map(v => v.id))
        },
        [onChange],
    )

    return (
        <Box style={{ padding: '0px 10px 10px' }}>
            <Autocomplete
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                multiple
                size="small"
                options={options}
                loading={loading}
                onChange={handleChange}
                value={labelOptions}
                getOptionLabel={option => option.name}
                getOptionSelected={(option, value) => {
                    return (
                        option?.id === value ||
                        option?.id === value?.id ||
                        option?.name?.toLowerCase() ===
                            value?.name?.toLowerCase()
                    )
                }}
                renderTags={(value, getUserProps) =>
                    value.map((option, index) => (
                        <Chip
                            variant="outlined"
                            style={{
                                backgroundColor: '#bfbfbf',
                                textShadow: '1px 1px 1px #000',
                                color: 'white',
                            }}
                            label={option?.name}
                            {...getUserProps({ index })}
                            size="small"
                        />
                    ))
                }
                renderInput={params => (
                    <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Filtro por Usuários"
                    />
                )}
            />
        </Box>
    )
}
