import React, { useState, useEffect, useContext, useReducer, useCallback } from 'react'
import { toast } from 'react-toastify'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import EditIcon from '@material-ui/icons/Edit'

import MainContainer from '../../components/MainContainer'
import MainHeader from '../../components/MainHeader'
import MainHeaderButtonsWrapper from '../../components/MainHeaderButtonsWrapper'
import Title from '../../components/Title'

import api from '../../services/api'
import { i18n } from '../../translate/i18n'
import TableRowSkeleton from '../../components/TableRowSkeleton'
import ConfirmationModal from '../../components/ConfirmationModal'
import toastError from '../../errors/toastError'
import CustomFieldModal from '../../components/CustomFieldModal'
import { SocketContext } from 'context/Socket/SocketContext'
import { AuthContext } from 'context/Auth/AuthContext'

const reducer = (state, action) => {
    if (action.type === 'LOAD') {
        const tags = action.payload
        const newTags = []

        tags.forEach(customField => {
            const index = state.findIndex(s => s.id === customField.id)
            if (index !== -1) {
                state[index] = customField
            } else {
                newTags.push(customField)
            }
        })

        return [...state, ...newTags]
    }

    if (action.type === 'UPDATE') {
        const customField = action.payload
        const index = state.findIndex(s => s.id === customField.id)

        if (index !== -1) {
            state[index] = customField
            return [...state]
        } else {
            return [customField, ...state]
        }
    }

    if (action.type === 'DELETE') {
        const tagId = action.payload

        const index = state.findIndex(s => s.id === tagId)
        if (index !== -1) {
            state.splice(index, 1)
        }
        return [...state]
    }

    if (action.type === 'RESET') {
        return []
    }
}

const useStyles = makeStyles(theme => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: 'scroll',
        ...theme.scrollbarStyles,
    },
}))

const CustomField = () => {
    const classes = useStyles()
    const socketManager = useContext(SocketContext)
    const { user } = useContext(AuthContext)

    const [loading, setLoading] = useState(false)
    const [pageNumber, setPageNumber] = useState(1)
    const [hasMore, setHasMore] = useState(false)
    const [deletingField, setDeletingField] = useState(null)
    const [selectedFieldId, setSelectedFieldId] = useState(null)
    const [fieldModalOpen, setFieldModalOpen] = useState(false)
    const [confirmModalOpen, setConfirmModalOpen] = useState(false)
    const [smart, setSmart] = useState('')
    const [fields, dispatch] = useReducer(reducer, [])

    const fetchFields = useCallback(() => {
        api.get('/custom-field', {
            filters: { smart },
            page: pageNumber,
        })
            .then(({ data }) => {
                dispatch({ type: 'LOAD', payload: data.data })
                setHasMore(data.total > data.data * pageNumber)
                setLoading(false)
            })
            .catch(err => {
                toastError(err)
            })
    }, [smart, pageNumber])

    useEffect(() => {
        dispatch({ type: 'RESET' })
        setPageNumber(1)
    }, [smart])

    useEffect(() => {
        setLoading(true)
        const delayDebounceFn = setTimeout(() => {
            fetchFields()
        }, 500)
        return () => clearTimeout(delayDebounceFn)
    }, [smart, pageNumber, fetchFields])

    useEffect(() => {
        const socket = socketManager.GetSocket(user.companyId)

        socket.on('customField', data => {
            if (data.action === 'update' || data.action === 'create') {
                dispatch({ type: 'UPDATE', payload: data.customField })
            }

            if (data.action === 'delete') {
                dispatch({ type: 'DELETE', payload: +data.customFieldId })
            }
        })

        return () => {
            socket.disconnect()
        }
    }, [user, socketManager])

    const handleAddField = () => {
        setSelectedFieldId(null)
        setFieldModalOpen(true)
    }
    const handleEditField = field => {
        setSelectedFieldId(field.id)
        setFieldModalOpen(true)
    }
    const handleCloseModal = () => {
        setSelectedFieldId(null)
        setFieldModalOpen(false)
    }

    const handleSearch = event => {
        setSmart(event.target.value.toLowerCase())
    }

    const handleDeleteField = async fieldId => {
        try {
            await api.delete(`/custom-field/${fieldId}`)
            toast.success(i18n.t('customField.toasts.deleted'))
        } catch (err) {
            toastError(err)
        }
        setDeletingField(null)
        setSmart('')
        setPageNumber(1)
    }

    const loadMore = () => {
        setPageNumber(prevState => prevState + 1)
    }

    const handleScroll = e => {
        if (!hasMore || loading) return
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore()
        }
    }

    return (
        <MainContainer>
            <CustomFieldModal
                open={fieldModalOpen}
                onClose={handleCloseModal}
                aria-labelledby="form-dialog-title"
                fieldId={selectedFieldId}></CustomFieldModal>
            <ConfirmationModal
                title={
                    deletingField &&
                    `${i18n.t('customField.confirmationModal.deleteTitle')} ${
                        deletingField.name
                    }?`
                }
                open={confirmModalOpen}
                onClose={setConfirmModalOpen}
                onConfirm={() => handleDeleteField(deletingField.id)}>
                {i18n.t('customField.confirmationModal.deleteMessage')}
            </ConfirmationModal>
            <MainHeader>
                <Title>{i18n.t('customField.title')}</Title>
                <MainHeaderButtonsWrapper>
                    <TextField
                        placeholder={i18n.t('list.searchPlaceholder')}
                        type="search"
                        value={smart}
                        onChange={handleSearch}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon style={{ color: 'gray' }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddField}>
                        {i18n.t('customField.buttons.add')}
                    </Button>
                </MainHeaderButtonsWrapper>
            </MainHeader>
            <Paper
                className={classes.mainPaper}
                variant="outlined"
                onScroll={handleScroll}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">
                                {i18n.t('customField.table.name')}
                            </TableCell>
                            <TableCell align="center">
                                {' '}
                                {i18n.t('customField.table.actions')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {fields.map(field => (
                                <TableRow key={field.id}>
                                    <TableCell align="center">
                                        {field.name}
                                    </TableCell>
                                    <TableCell align="center">
                                        <IconButton
                                            size="small"
                                            onClick={() =>
                                                handleEditField(field)
                                            }>
                                            <EditIcon />
                                        </IconButton>

                                        <IconButton
                                            size="small"
                                            onClick={e => {
                                                setConfirmModalOpen(true)
                                                setDeletingField(field)
                                            }}>
                                            <DeleteOutlineIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {loading && <TableRowSkeleton columns={4} />}
                        </>
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    )
}

export default CustomField
