import React, { useCallback, useEffect, useRef, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import api from '../../services/api'
import { uniq } from 'lodash'

export default function ApreUser({
    value,
    onChange,
    multiple,
    limitShow = 1,
    label = 'Usuário Apresenta.me',
    ...props
}) {
    let timeout = useRef()
    const [open, setOpen] = useState(false)
    const [options, setOptions] = useState([])
    const [labelOptions, setLabelOptions] = useState([])
    const loading = open && options.length === 0

    const validProps = {
        className: props.className,
        style: props.style,
        disableClearable: props.disableClearable,
    }

    useEffect(() => {
        if (!value || (multiple && !value.length)) return

        const getLabel = async values => {
            if (!values) return

            return (await api.get(`/apre/users`, { filters: { id: values } }))
                .data.data
        }

        if (multiple)
            new Promise(async resolve => {
                const labels = await getLabel(value)
                setLabelOptions(labels)
                resolve(true)
            })
        else
            getLabel(value).then(label => {
                setLabelOptions(label[0])
            })
    }, [value, multiple])

    const getOptions = useCallback(
        smart => {
            clearTimeout(timeout.current)
            timeout.current = setTimeout(() => {
                api.get('/apre/users', { filters: { smart } }).then(r => {
                    setOptions(r.data.data)
                })
            }, 300)
        },
        [timeout],
    )

    useEffect(() => {
        if (!loading) return undefined

        getOptions()
    }, [loading, getOptions])

    useEffect(() => {
        if (!open) setOptions([])
    }, [open])

    const handleChange = useCallback(
        (e, value) => {
            setLabelOptions(multiple ? uniq(value, 'id') : value)
            if (!value) return onChange(null)

            if (multiple) return onChange(uniq(value, 'id').map(v => v.id))

            onChange(value.id)
        },
        [multiple, onChange],
    )

    return (
        <Autocomplete
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={option => option.name || ''}
            onInputChange={(_, value, reason) =>
                reason === 'input' ? getOptions(value) : null
            }
            options={options}
            loading={loading}
            onChange={handleChange}
            multiple={multiple}
            value={labelOptions}
            {...validProps}
            limitTags={limitShow}
            disableCloseOnSelect={multiple}
            renderTags={value => {
                const numTags = value.length
                const limitTags = limitShow

                return (
                    <>
                        {value.slice(0, limitTags).map(option => option.name)}

                        {numTags > limitTags && ` +${numTags - limitTags}`}
                    </>
                )
            }}
            renderOption={option => option.name}
            renderInput={params => (
                <TextField
                    {...params}
                    {...props}
                    label={label}
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    )
}
