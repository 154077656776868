import { Button, Drawer, makeStyles } from '@material-ui/core';
import MessageIcon from '@material-ui/icons/Message'
import ListIcon from '@material-ui/icons/List'
import TimerIcon from '@material-ui/icons/Timer'
import RateReviewIcon from '@material-ui/icons/RateReview'
import GroupIcon from '@material-ui/icons/Group'
import PersonIcon from '@material-ui/icons/Person'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'
import CallSplitIcon from '@material-ui/icons/CallSplit'
import DoneIcon from '@material-ui/icons/Done'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import WifiIcon from '@material-ui/icons/Wifi'

const useStyles = makeStyles(theme => ({
    chatOptions: {
        ...theme.scrollbarStylesThin,
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
    },

    chatOption: {
        display: 'flex',
        justifyContent: 'center',
        '& > button': {
            width: '100%',
            '& > span': {
                display: 'flex',
                flexDirection: 'column',
                fontSize: '0.7rem',
            },
        },
    },
}))

const types = [
    { type: 'message', label: 'Mensagem', icon: <MessageIcon /> },
    { type: 'menu', label: 'Menu', icon: <ListIcon /> },
    { type: 'sleep', label: 'Espera', icon: <TimerIcon /> },
    { type: 'ask', label: 'Pergunta', icon: <RateReviewIcon /> },
    { type: 'queue', label: 'Setor', icon: <GroupIcon /> },
    { type: 'user', label: 'Atendente', icon: <PersonIcon /> },
    { type: 'tag', label: 'Tag', icon: <LocalOfferIcon /> },
    { type: 'notify', label: 'Notificar', icon: <NotificationsActiveIcon /> },
    { type: 'condition', label: 'Condição', icon: <CallSplitIcon style={{ transform: 'rotate(180deg)' }} /> },
    { type: 'httpRequest', label: 'Requisição HTTP', icon: <WifiIcon /> },
    { type: 'findPerson', label: 'Pessoa no Sistema', icon: <AssignmentIndIcon /> },
    { type: 'sendInvoice', label: 'Enviar Boleto', icon: <MonetizationOnIcon /> },
    { type: 'connectionTransfer', label: 'Transferir Conexão', icon: <DoneIcon /> },
    { type: 'finish', label: 'Finalizar Bot', icon: <DoneIcon /> },
]

function OptionButton({option, onDragStart, onAddNode}) {
    const classes = useStyles()

    return (
        <div className={classes.chatOption} onDragStart={event => onDragStart(event, option.type)} draggable>
            <Button onClick={() => onAddNode(option.type)}>{option.icon} {option.label}</Button>
        </div>
    )
} 


function ChatOptions({ reactFlowInstance, onAddNode, ...data }) {
    const classes = useStyles();

    const onDragStart = (event, nodeType) => {
        event.dataTransfer.setData('application/reactflow', nodeType)
        event.dataTransfer.effectAllowed = 'move'
    }

    return (
        <>
            <Drawer variant="permanent"
                PaperProps={{ style: { position: "unset", justifyContent: 'space-between', } }} BackdropProps={{ style: { position: "absolute" } }}
            >
                <div className={classes.chatOptions}>
                    {types.map((option, i) => 
                        <OptionButton key={i} option={option} onDragStart={onDragStart} onAddNode={onAddNode} />
                    )}
                </div>
            </Drawer>
        </>
    )
}

export default ChatOptions
