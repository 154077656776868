import { useField } from 'formik'
import ApreUser from '.'

const FormikApreUser = props => {
    const [field, , helpers] = useField(props.name)

    const handleChange = value => {
        helpers.setValue(value)
    }

    return <ApreUser {...field} {...props} onChange={handleChange} />
}

export default FormikApreUser
