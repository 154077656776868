import React from 'react'
import moment from 'moment'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

import Filter from '../components/Filter'
import useStyles from '../components/Style'
import useDashboard from 'hooks/useDashboard'
import DashboardBarChart from '../components/DashboardBarChart'

function formatTime(minutes) {
    return moment().startOf("day").add(minutes, "minutes").format("HH[h] mm[m] ss[s]");
}

const Tickets = () => {
    const classes = useStyles()

    const { findTicketsDashboard } = useDashboard()

    const [data, setData] = React.useState({})
    const [loading, setLoading] = React.useState(false)
    
    async function onFilter(params) {
        const data = await findTicketsDashboard(params)
        setData({
            ...data,
            days: data.days?.map(current => ({...current, day: moment(current.day).format('DD/MM/YYYY')}))
        })
    }

    return (
        <div>
            <Container maxWidth="xl" className={classes.container}>
                <Grid container spacing={2} justifyContent="flex-start">
                    <Filter onFilter={onFilter} setLoading={setLoading} loading={loading} />

                    <Grid item xs={12} >
                        <DashboardBarChart 
                            title="Atendimentos Abertos" 
                            label="Atendimentos" 
                            xKey="name" 
                            dataKey="tickets" 
                            data={data.usersOpened} 
                            formatter={(value, name, props) => [value, 'Atendimentos']} 
                        />
                    </Grid>

                    <Grid item xs={12} >
                        <DashboardBarChart 
                            title="Atendimentos Finalizados" 
                            label="Atendimentos" 
                            xKey="name" 
                            dataKey="tickets" 
                            data={data.usersClosed} 
                            formatter={(value, name, props) => [value, 'Atendimentos']}
                        />
                    </Grid>

                    <Grid item xs={12} >
                        <DashboardBarChart 
                            title="Tempo Médio de Resposta" 
                            label="Minutos" 
                            xKey="name" 
                            dataKey="replyAvg" 
                            data={data.replyAvg} 
                            formatter={(value, name, props) => [formatTime(value), 'Tempo']} 
                        />
                    </Grid>

                    <Grid item xs={12} >
                        <DashboardBarChart 
                            title="Tempo Médio de Aceite" 
                            label="Minutos" 
                            xKey="name" 
                            dataKey="minutes" 
                            data={data.usersAccept} 
                            formatter={(value, name, props) => [formatTime(value), 'Tempo']} 
                        />
                    </Grid>

                    <Grid item xs={12} >
                        <DashboardBarChart 
                            title="Atendimentos por Dia" 
                            label="Atendimentos" 
                            xKey="day" 
                            dataKey="tickets" 
                            data={data.days} 
                            formatter={(value, name, props) => [value, 'Atendimentos']} 
                        />
                    </Grid>

                </Grid>
            </Container>
        </div>
    )
}

export default Tickets
